import { Dialog, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { DropDownItem } from "../../molecules/Dropdown";
import { SapService } from "../../../services/api";
import FormInput from "../../molecules/FormInput";
import useRefresh from "../../../hooks/useRefresh";
import FormTemplate from "../../templates/FormTemplate";
import { Ticket } from "../../../services/swagger";

interface CreateServiceCallDialogProps {
  open: boolean;
  ticket: Ticket;
  onSubmit?: () => void;
  onClose?: () => void;
}

export default function CreateServiceCallDialog(
  props: CreateServiceCallDialogProps
) {
  const { ticket } = props;
  const [title, setTitle] = useState("");

  const [problemTypes, setProblemTypes] = useState<DropDownItem[]>([]);
  const [callTypes, setCallTypes] = useState<DropDownItem[]>([]);
  const [priorities, setPriorities] = useState<DropDownItem[]>([]);
  const [customers, setCustomers] = useState<DropDownItem[]>([]);
  const [contacts, setContacts] = useState<DropDownItem[]>([]);
  const [technicians, setTechnicians] = useState<DropDownItem[]>([]);

  const [problemType, setProblemType] = useState("");
  const [callType, setCallType] = useState("");
  const [priority, setPriority] = useState("");
  const [customer, setCustomer] = useState("");
  const [contact, setContact] = useState("");
  const [technician, setTechnician] = useState("");

  const refresh = useRefresh();

  const loadProblemTypes = async () => {
    const res = await SapService.getProblemTypes();
    if (res?.data?.data)
      setProblemTypes(
        res.data.data.map((row: any) => {
          return {
            name: row.Name,
            value: row.prblmTypID.toString(),
          };
        })
      );
  };
  const loadCallTypes = async () => {
    const res = await SapService.getCallTypes();
    if (res?.data?.data)
      setCallTypes(
        res.data.data.map((row: any) => {
          return {
            name: row.Name,
            value: row.callTypeID.toString(),
          };
        })
      );
  };
  const loadPriorities = async () => {
    const res = await SapService.getPriorities();
    if (res?.data?.data)
      setPriorities(
        res.data.data.map((row: any) => {
          return {
            name: row.Descript,
            value: (row.Descript as string).charAt(0),
          };
        })
      );
  };
  const loadCustomers = async () => {
    const res = await SapService.getCustomers();
    if (res?.data?.data)
      setCustomers(
        res.data.data.customers.map((row: any) => {
          return {
            name: row.CardName,
            value: row.CardCode,
          };
        })
      );
  };
  const loadContacts = async () => {
    const res = await SapService.getContacts({ customerNo: customer });
    if (res?.data?.data)
      setContacts(
        res.data.data.map((row: any) => {
          return {
            name: row.Name,
            value: row.CntctCode.toString(),
          };
        })
      );
  };
  const loadTechnicians = async () => {
    const res = await SapService.getEmployees();
    if (res?.data?.data)
      setTechnicians(
        res.data.data.map((row: any) => {
          return {
            name: [row.firstName, row.lastName].join(" "),
            value: row.empID.toString(),
          };
        })
      );
  };

  useEffect(() => {
    if (!props.open) return;
    setTitle(ticket.title);
    loadProblemTypes();
    loadCallTypes();
    loadPriorities();
    loadTechnicians();
    loadCustomers();
  }, [props.open]);

  useEffect(() => {
    loadContacts();
  }, [customer]);

  useEffect(() => {
    if (problemTypes.length > 0)
      setProblemType(problemTypes[0].value as string);
  }, [problemTypes]);

  useEffect(() => {
    if (callTypes.length > 0) setCallType(callTypes[0].value as string);
  }, [callTypes]);

  useEffect(() => {
    if (priorities.length > 0) setPriority(priorities[0].value as string);
  }, [priorities]);

  useEffect(() => {
    if (customers.length > 0) setCustomer(customers[0].value as string);
  }, [customers]);

  useEffect(() => {
    if (contacts.length > 0) setContact(contacts[0].value as string);
    else setContact("");
  }, [contacts]);

  useEffect(() => {
    if (technicians.length > 0) setTechnician(technicians[0].value as string);
  }, [technicians]);

  const handleSubmit = async () => {
    if (!ticket._id) return;
    const res = await SapService.createServiceCall({
      ticketId: ticket._id,
      createServiceCallBody: {
        callType,
        contactCode: contact,
        customerNo: customer,
        priority,
        problemType,
        technician,
        title,
      },
    });

    if (res?.data?.success) {
      refresh();
      props.onClose?.();
    }
  };

  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <FormTemplate
        title="Create Service Call"
        onSubmit={handleSubmit}
        onCancel={props.onClose}
      >
        <Stack spacing={2}>
          <FormInput
            label="Title"
            value={title}
            placeholder="Service Call Title"
            required
            field="title"
            type="string"
            onChange={(v) => setTitle(v as string)}
          />
          <FormInput
            field="problemType"
            label="Problem Type"
            onChange={(v) => {
              setProblemType(v as string);
            }}
            type="list"
            required
            value={problemType}
            list={problemTypes}
          />
          <FormInput
            field="callType"
            label="Call Type"
            onChange={(v) => {
              setCallType(v as string);
            }}
            type="list"
            required
            value={callType}
            list={callTypes}
          />
          <FormInput
            field="priority"
            label="Priority"
            onChange={(v) => {
              setPriority(v as string);
            }}
            type="list"
            required
            value={priority}
            list={priorities}
          />
          <FormInput
            field="customer"
            label="Customer"
            onChange={(v) => {
              setCustomer(v as string);
            }}
            type="list"
            required
            value={customer}
            list={customers}
          />
          <FormInput
            field="contact"
            label="Contact"
            onChange={(v) => {
              setContact(v as string);
            }}
            type="list"
            required
            value={contact}
            list={contacts}
          />
          <FormInput
            field="technician"
            label="Technician"
            onChange={(v) => {
              setTechnician(v as string);
            }}
            type="list"
            required
            value={technician}
            list={technicians}
          />
        </Stack>
      </FormTemplate>
    </Dialog>
  );
}
