import { DependencyList, EffectCallback, useEffect, useRef } from "react";

const useDidMountEffect = (callback: EffectCallback, deps?: DependencyList) => {
  const didMount = useRef(false);

  useEffect(() => {
    if (didMount.current) callback();
    else didMount.current = true;
  }, deps);
};

export default useDidMountEffect;
