import { Box, Collapse, Drawer, Paper } from "@mui/material";
import SideBar, { sideBarWidth } from "../organisms/SideBar";
import TopBar from "../organisms/TopBar";
import useBreakpoint from "../../hooks/useBreakpoint";
import { useGlobalContext } from "../../contexts/Global";

interface ManagementTemplateProps {
  paper?: boolean;
  noPadding?: boolean;
  children?: any;
}

export default function ManagementTemplate(props: ManagementTemplateProps) {
  const { context, setContext } = useGlobalContext();
  const breakpoint = useBreakpoint();

  return (
    <Box display="flex" height={1}>
      <TopBar />
      {breakpoint.md ? (
        <Box p={2} bgcolor="background.paper">
          <SideBar />
        </Box>
      ) : (
        <Drawer
          open={context.sidebar}
          onClose={() => setContext({ ...context, sidebar: false })}
          elevation={4}
          anchor="left"
          variant="temporary"
          sx={{
            transitionDuration: "200ms",
            width: context.sidebar ? sideBarWidth : 0,
            flexShrink: 0,
            [`& .MuiDrawer-Paper`]: {
              height: "100%",
              boxSizing: " border-box",
              background: (theme) =>
                breakpoint.md ? theme.palette.background.paper : "transparent",
              borderRight: "none",
              position: "relative",
              width: sideBarWidth,
            },
          }}
        >
          <SideBar />
        </Drawer>
      )}
      <Box width={1} overflow="auto" display="flex" flexDirection="column">
        <Box
          sx={{
            pt: 10.5,
            flexGrow: 1,
            bgcolor: (theme) => theme.palette.background.paper,
            width: 1,
            height: 1,
            pr: breakpoint.md ? 2 : 0,
          }}
        >
          <Box
            sx={{
              width: 1,
              height: 1,
              overflow: "auto",
              bgcolor: (theme) => theme.palette.background.paper,
              p: props.noPadding ? 0 : 2,
            }}
          >
            {props.paper ? (
              <Paper
                sx={{
                  bgcolor: "background.default",
                  height: 1,
                  overflow: "hidden",
                }}
              >
                {props.children}
              </Paper>
            ) : (
              props.children
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
