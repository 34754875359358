interface WebLink {
  label: string;
  link: string;
}

interface AppConfig {
  name: string;
  nickname: string;
  domain: string;
  logo: {
    image: string;
    link: string;
  };
  links: {
    website: WebLink;
    facebook: WebLink;
    x: WebLink;
    instagram: WebLink;
    youtube: WebLink;
    linkedin: WebLink;
  };
  contact: {
    email: string;
    phone: string;
    address: string;
  };
}

export default {
  domain: "support.akcaba.com",
  name: "Akçaba Communications",
  nickname: "Akçaba",
  logo: {
    image: require("../assets/logo-full.png"),
    link: "https://akcaba.com",
  },
  links: {
    website: { label: "Akçaba Communications", link: "https://akcaba.com" },
    facebook: {
      label: "Akçaba Communications",
      link: "https://www.facebook.com/akcomm/",
    },
    instagram: {
      label: "akcaba_communications",
      link: "https://www.instagram.com/akcaba_communications/",
    },
    linkedin: {
      label: "Akçaba Communications",
      link: "https://www.linkedin.com/company/akcaba-communications/?originalSubdomain=cy",
    },
    x: { label: "AkcabaComm", link: "https://x.com/AkcabaComm" },
    youtube: {
      label: "AkcabaComm",
      link: "https://www.youtube.com/akcabacomm",
    },
  },
  contact: {
    email: "support@akcaba.com",
    phone: "(+90) 392 229 1515",
    address: "4 Tuna Sok., Kumsal, Lefkoşa",
  },
} as AppConfig;
