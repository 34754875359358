import {
  Box,
  Container,
  Divider,
  Grid,
  IconButton,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { useNavigate, useParams } from "react-router";
import { TicketService } from "../../../services/api";
import { useEffect, useState } from "react";
import { Ticket } from "../../../services/swagger";
import TicketResponse from "../../organisms/Ticket/TicketResponse";
import TicketInfo from "../../organisms/Ticket/TicketInfo";
import useRefresh from "../../../hooks/useRefresh";
import TicketDetails from "../../organisms/Ticket/TicketDetails";
import CreateTicketResponse from "../../organisms/Ticket/CreateTicketResponse";
import TicketTasks from "../../organisms/Ticket/TicketTasks";
import TicketServiceCall from "../../organisms/Ticket/TicketServiceCall";
import Authorized from "../../atoms/Authorized";
import CloseIcon from "@mui/icons-material/Close";

interface ManageTicketPageTemplate {
  template: JSX.Element;
}

export default function ManageTicketPage(props: ManageTicketPageTemplate) {
  const navigate = useNavigate();
  const { _id } = useParams();
  const [ticket, setTicket] = useState<Ticket>();

  const loadTicket = async () => {
    if (!_id) return;
    const res = await TicketService.getTicket({ id: _id });
    if (res?.data?.data) setTicket(res.data.data);
  };

  useRefresh(loadTicket, "ticket");

  useEffect(() => {
    loadTicket();
  }, []);

  return (
    <props.template.type {...props.template.props}>
      <Box bgcolor="background.paper" height={1} pt={2} pb={2}>
        <Container>
          <Stack spacing={2} pb={2}>
            <Paper
              sx={{
                p: 2,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                bgcolor: "background.default",
              }}
            >
              <Typography fontWeight={600}>Ticket #{ticket?.id}</Typography>
              <IconButton onClick={() => navigate(-1)}>
                <CloseIcon />
              </IconButton>
            </Paper>
            <Box>
              <Grid container spacing={2}>
                <Grid item xs={12} md={8}>
                  <Stack spacing={2}>
                    <TicketDetails ticket={ticket} />
                    <Divider />
                    {ticket?.responses.map((response) => {
                      if (!ticket?._id) return null;
                      return (
                        <TicketResponse
                          key={response._id}
                          ticket={ticket}
                          response={response}
                        />
                      );
                    })}
                    {ticket?.id && (
                      <CreateTicketResponse ticketId={ticket.id.toString()} />
                    )}
                  </Stack>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Box>
                    <Stack spacing={2}>
                      <Authorized role="user">
                        {ticket && <TicketInfo ticket={ticket} />}
                        <Authorized role="agent">
                          {ticket && <TicketTasks ticket={ticket} />}
                          {ticket && <TicketServiceCall ticket={ticket} />}
                        </Authorized>
                      </Authorized>
                    </Stack>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Stack>
        </Container>
      </Box>
    </props.template.type>
  );
}
