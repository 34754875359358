import { Paper } from "@mui/material";
import FormInput from "../../molecules/FormInput";
import { useEffect, useState } from "react";
import { Stack } from "@mui/system";

export interface TicketBodyFields {
  title: string;
  details: string;
}

interface TicketBodyProps {
  onChange: (fields: TicketBodyFields) => void;
}

export default function TicketBody(props: TicketBodyProps) {
  const [title, setTitle] = useState("");
  const [details, setDetails] = useState("");

  useEffect(() => {
    props.onChange({ title, details });
  }, [title, details]);

  return (
    <Paper sx={{ p: 2, bgcolor: "background.default" }}>
      <Stack spacing={2}>
        <FormInput
          required
          field="title"
          label="Title"
          value={title}
          onChange={(value) => setTitle(value as string)}
          type="string"
          placeholder="Broken Item No. 123"
        />
        <FormInput
          required
          field="details"
          label="Details"
          value={details}
          onChange={(value) => setDetails(value as string)}
          type="string"
          multiline
          minRows={8}
          placeholder="Ticket details"
        />
      </Stack>
    </Paper>
  );
}
